/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, {useEffect, useState} from "react";

import { ActionData } from '../../../../models/classes/ActionData';
import { PanelData } from '../../../../models/classes/PanelData';
import { WidgetData } from '../../../../models/classes/WidgetData';

import '@reach/menu-button/styles.css';
import './styles/index.css';

import PanelDataLayoutComponent from './components/PanelDataLayoutComponent';
import PanelTableComponent from './components/PanelTableComponent';
import PanelTreeComponent from './components/PanelTreeComponent';
import PanelTimelineComponent from './components/PanelTimelineComponent';
import PanelChartComponent from "./components/PanelChartComponent";
import PanelCalendarActivity from './components/PanelCalendarActivity';

interface Props {
    actions: ActionData[];
    panelData: PanelData;
    widgetData: WidgetData;
    panelWidth: number;
    onLoading: Function;
}

const PanelDataComponent: React.FC<Props> = (props) => {
    const {
        actions,
        panelData,
        widgetData,
        panelWidth,
        onLoading
    } = props;

    const {
        PanelName,
        Footer,
        LayoutFormat
    } = panelData;

    const [mountTableComponent, setMountTableComponent] = useState(false);
    const [isPanelHasLoaded, setPanelState] = useState<boolean>(false);

    useEffect(() => {
        setPanelState(panelData?.IsPanelHasLoaded);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(LayoutFormat === "Table") {
            setMountTableComponent(true);
            if(panelData?.TableOption?.TableType === "Generic" && !isPanelHasLoaded) {
                setMountTableComponent(false);

                // delay the visibility of panel table component so the glitch from timeline item popup is hidden
                setTimeout(() => {
                    setMountTableComponent(true);
                }, 100);
            }
            setPanelState(true);
        }
        // eslint-disable-next-line
    }, [props, PanelName]);

    return (
        <PanelDataLayoutComponent layoutName={`${widgetData.WidgetName}_${PanelName}`}>
            {
                LayoutFormat === "Chart"?
                    <PanelChartComponent
                        widgetData={widgetData}
                        panelData={panelData}
                        panelWidth={panelWidth}
                        onLoading={(item: any) => onLoading(item)}
                    />: null
            }
            {
                LayoutFormat === "Table"?
                    <PanelTableComponent
                        actions={actions}
                        widgetName={widgetData.WidgetName}
                        workspaceName={widgetData.WorkspaceName}
                        panelData={panelData}
                        footerActionButton={
                            Footer?.Actions?
                                Footer.Actions: undefined
                        }
                        style={{
                            visibility: mountTableComponent? "visible": "hidden"
                        }}
                        onLoading={(item: any) => onLoading(item)}
                    />: null
            }
            {
                LayoutFormat === "Tree"?
                    <PanelTreeComponent
                        widgetName={widgetData.WidgetName}
                        panelData={panelData}
                    />: null
            }
            {
                LayoutFormat === "Timeline"?
                    <PanelTimelineComponent
                        widgetName={widgetData.WidgetName}
                        panelData={panelData}
                    />: null
            }
            {
                LayoutFormat === "CalendarActivity"?
                    <PanelCalendarActivity
                        widgetName={widgetData.WidgetName}
                        panelData={panelData}
                    />: null
            }
        </PanelDataLayoutComponent>
    );
};

export default PanelDataComponent;