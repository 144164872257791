/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import { InputNumberProps } from 'primereact/inputnumber';
import { IListofValue } from '../interfaces/IListofValue';
import { IPublishComponent, ISubscribeComponent } from './PanelData';
import { WorkspaceData } from './WorkspaceData';
import { ActionData } from './ActionData';

export class PanelFieldData {
    FieldName: string = "";
    FieldLabel: string = "";
    DataType: string = "";
    FieldFormat: string = "";
    FieldWidth: string = "100%";
    FieldValue: any = null;
    Tooltip: string = "";
    TooltipPosition:  ("top" | "bottom" | "left" | "right") = "right"

    JustifyContent: ("flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "start" | "end" | "left" | "right" | "normal" | "stretch" | "safe center" | "unsafe center") = "space-between"
    //#region DisplayText
    LabelFormatStyle: any = {}    
    ValueFormatStyle: any = {}
    //#endregionDisplayText

    //Chart Label Option
    Position: (string | "Prefix" | "Suffix") = "Prefix";

    //<editor-fold desc="Filter is used for table data filter in it's column">
    HideFilter: boolean = false;
    FilterType: string = "";
    FilterableField: boolean = true;
    FilterItems: any[] = [];
    //</editor-fold>
    
    SortableField: boolean = true;
    
    GroupLabel: any = null; // for data table
    ExportType: ("Value" | "Description") = "Value"
    Hidden: boolean = false;
    InputType: string = "";
    IsBatchField: boolean = false; // for fields in popup dialog
    IntRange: {min: number, max: number} = {min: 0, max: 0};
    ColorPickType?: ("rgb" | "hex") = "rgb"

    ValueSource: any = "";
    DefaultValue: any = "";
    DefaultValueAPI: string = "";
    DefaultValueAPIParams: any = [];

    Mandatory: boolean = false;
    Editable: boolean = true;
    EditableLOV: boolean = true;
    DisplayPosition: string = "";

    // #region for dropdown
    DisplayAllField: boolean = false;
    DropdownOptions: any[] = []; // ex: [{ShowValue: false}] P.S: Currently doesn't work on dialog since it only show the label
    ListOfValues: (IListofValue | any)[] = [];
    ListOfValuesAPI: string = "";
    // #endregion
    Value: any = null;

    // #region custom properties for editor
    EditorHeight: any = null;
    EditorWidth: any = null;
    // #endregion

    InputNumberProps?: InputNumberProps;

    ChartField: string = ""; // used for FieldsToChartConfigConversion

    //#region datepicker
    MinDate: string = "";
    MaxDate: string = "";
    //#endregion

    //Example Data
    //"Locale": {
    //     "en-US": {
    //       "FieldLabel": "Help Code"
    //     },
    //     "id-ID": {
    //       "FieldLabel": "Kode Bantuan"
    //     }
    //}
    Locale: any = {};

    PublishComponent?: IPublishComponent[];
    SubscribeComponent?: ISubscribeComponent[];
    FieldConfiguration?: {
        FieldValue: string,
        DialogWidth: string,
        Workspace: WorkspaceData 
    };
    VirtualScrollerOptions: { Enable: boolean, ItemSize: number } | undefined;
    Actions: ActionData[] | null | undefined = null;
    validationStatus: any = null;
}
