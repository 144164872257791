/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React from "react";
import {Button} from "primereact/button";

import {ActionData} from "../../../../../../../models/classes/ActionData";
import {openDialog} from "../utils/dialog";
import __t from "../../../../../../../utils/translation";
import { PanelFieldData } from "../../../../../../../models/classes/PanelFieldData";
import { useGlobalState } from "../../../../../../../App";
import { checkPermission } from "../../../../../../../utils/permission";
import { confirmBeforeSubmit } from "../utils/confirmDialog";

const TableFooterElement = (footerActionButton: ActionData[], setDialog: Function, tableFields: PanelFieldData[], widgetName: string, panelName: string): JSX.Element => {
    const [appData, setAppData] = useGlobalState('appData');
    return (
        <div className="p-clearfix" style={{width:'100%'}}>
            <div className="p-grid" style={{marginTop:'10px', marginLeft: '0px'}}>
            {footerActionButton.map((action, actionIndex) => (                
                !checkPermission(action) ?
                 <div style={{marginRight: '13px'}}>
                    <Button
                        className="p-button-raised"
                        raised
                        key={actionIndex}
                        label={__t(action, "ActionLabel")}
                        icon={`pi ${action.Icon}`}
                        iconPos={action.IconPosition}
                        style={{
                            backgroundColor: action.BackgroundColor || '#2196f3',
                            color: action.TextColor || '#ffffff',
                        }}
                        onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            if (action.ActionType === 'Submit') {
                                const data = {
                                    ...appData,
                                    Action: "SUBMIT",
                                    Type: "Submit",
                                    SubmitParameterMode: action.SubmitParameterMode,
                                    WidgetName: widgetName,
                                    PanelName: panelName,
                                    APIName: action.APIName
                                }
                                if (action.RequiredConfirmation) {
                                    confirmBeforeSubmit(action.ConfirmationMessage, () => {
                                        setAppData(data)
                                    }, () => {});
                                } else {
                                    setAppData(data)
                                }
                            } else {
                                const promise = openDialog(
                                    setDialog,
                                    action,
                                    tableFields,
                                    {},
                                    null,
                                    appData, 
                                    setAppData
                                );
                            }
                        }}
                    />
             </div>
             : null
                
            ))}
            </div>
        </div>
    );
};

export default TableFooterElement;
