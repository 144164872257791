/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React from "react";
import { PanelData } from '../../../../models/classes/PanelData';

interface Props {
    panelData: PanelData;
}

const PanelIframeComponent: React.FC<Props> = (props) => {
    const { panelData } = props;

    const {
        PanelWidth = "100%",
        PanelHeight = "100%",
        UrlEmbed,
        DisplayPosition,
        AllowFullScreen = true,
    } = panelData;

    return (
            <iframe
                title="embedded-content"
                width={PanelWidth}
                height={PanelHeight}
                src={UrlEmbed}
                allowFullScreen={AllowFullScreen}
                style={{
                    display: "block"
                }}
            />
    );
};

export default PanelIframeComponent;


