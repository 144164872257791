  import React, { useState, useRef, useEffect } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import { useForm, Controller } from 'react-hook-form';
  import Calendar from '@toast-ui/calendar';
  import '@toast-ui/calendar/dist/toastui-calendar.min.css';
  import { Button } from 'primereact/button';
  import { Dialog } from 'primereact/dialog';
  import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

  // Interfaces
  import { IAPIOption } from '../../../../../../models/interfaces/IAPIOption';
  import { IConsumeAPI } from '../../../../../../models/interfaces/IConsumeAPI';
  import { IFieldValue } from '../../../../../../models/interfaces/IFieldValue';

  // Models
  import { DialogField } from '../../../../../../models/classes/DialogField';
  import { PanelFieldData } from '../../../../../../models/classes/PanelFieldData';

  // Components
  import FieldGenerator from '../../../../../../utils/field_generator';

  // Redux
  import { consumeAPI } from '../../../../../../redux/actions/panelItem';
  import { State } from '../../../../../../redux/reducers';

  // Utils
  import { isEmpty } from '../../../../../../utils/validation';
  import api from '../../../../../../common/api';

  interface IPanelCalendarActivity {
    widgetName: string;
    panelData: any;
  }

  const PanelCalendarActivity: React.FC<IPanelCalendarActivity> = ({ widgetName, panelData }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const formRef = useRef(null);
    const dataOriginalRef = useRef<any[]>([]);
    const [calendar, setCalendar] = useState<Calendar | null>(null);
    const [view, setView] = useState<'month' | 'week' | 'day'>(panelData?.ActivityOption?.type || 'month');
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showViewDialog, setShowViewDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<any>(null);
    const [formData, setFormData] = useState<any>({});
    const [dataOriginal, setDataOriginal] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    const panelItemSelector = useSelector((state: State) => state.panelItem);
    const panelName = `${widgetName}_${panelData.PanelName}`;

    const { control, handleSubmit, formState: { errors }, reset, setValue } = useForm();

     // Add new helper functions to check PostActions
    const checkPostActionFlag = (actionName: string, flagName: string): boolean => {
      const action = panelData.Actions.find((action: any) => action.ActionName === actionName);
      return action?.PostActions.some((postAction: any) => postAction[flagName] === true) || false;
    };

    const canAddData = checkPostActionFlag('AddDataActivity', 'AddRowData');
    const canEditData = checkPostActionFlag('EditDataActivity', 'EditRowData');
    const canDeleteData = checkPostActionFlag('DeleteDataActivity', 'DeleteRowData');

    const formatApiEvent = (event: any) => {
      const originalData: any = {};
      const addAction = panelData.Actions.find((action: any) => action.ActionName === "AddDataActivity");
      if (addAction) {
        addAction.Fields.forEach((field: PanelFieldData) => {
          originalData[field.FieldName] = event[field.FieldName];
        });
      }
      const uniqueId = event.id || `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      return {
        id: event.id || uniqueId,
        calendarId: '1',
        title: event.EventName,
        start: new Date(event.StartDate),
        end: new Date(event.EndDate),
        backgroundColor: event.BackgroundColor,
        category: 'time',
        isAllday: false,
        ...originalData
      };
    };

    useEffect(() => {
      let calendarInstance: Calendar | null = null;
      
      if (containerRef.current) {
        calendarInstance = new Calendar(containerRef.current, {
          defaultView: view,
          usageStatistics: false,
          isReadOnly: !canAddData,
          month: {
            dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            startDayOfWeek: 0,
            isAlways6Weeks: false,
          },
          week: {
            dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            hourStart: 0,
            hourEnd: 24,
            eventView: true,
            taskView: false
          },
          useDetailPopup: false
        });

        calendarInstance.on('clickEvent', (eventInfo) => {
          const matchingData = dataOriginalRef.current.find((item:any) => 
            item.id === eventInfo.event.id
          );
          if (matchingData) {
            setSelectedEvent(matchingData);
            setShowViewDialog(true);
          }
        });

        calendarInstance.on('selectDateTime', (dateTimeInfo) => {
          calendarInstance?.clearGridSelections();
          handleAddEvent(dateTimeInfo);
        });

        setCalendar(calendarInstance);
      }

      return () => {
        if (calendarInstance) {
          calendarInstance.destroy();
        }
      };
    }, []);

    useEffect(() => {
      if (calendar) {
        loadCalendarData();
      }
    }, [calendar]);

    const loadCalendarData = async () => {
      if (!panelData?.LoadData?.APIName || !calendar) return;

      setIsLoading(true);
      try {
        const requestBody = {
          APIName: panelData.LoadData.APIName,
          inputParameterValues: []
        };

        const apiOption: IAPIOption = {
          path: "query_management",
          method: "POST",
          data: requestBody
        };

        const response = await api(apiOption);
        
        if (response.success) {
          const events = response.data.map((event: any) => formatApiEvent(event));
          // Update both the ref and state
          dataOriginalRef.current = events;
          setDataOriginal(events);
          
          // Clear existing events and create new ones
          calendar.clear();
          calendar.createEvents(events);
          
          // Force calendar to refresh view
          calendar.render();
        }
      } catch (error) {
        console.error('Error loading calendar data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const createDialogField = (field: PanelFieldData, value: any = null) => {
      return new DialogField(
        field,
        false,
        value || field.DefaultValue?.FieldValue || null
      );
    };

    const handleAddEvent = (dateTimeInfo: any) => {
      const startDateTime = new Date(dateTimeInfo.start);
      const endDateTime = new Date(dateTimeInfo.end || startDateTime.getTime() + (60 * 60 * 1000));
    
      // Format with full date and time components
      const initialData: any = {
        StartDate: startDateTime,
        EndDate: endDateTime
      };
    
    
      // Mengambil fields dari AddDataActivity action
      const addAction = panelData.Actions.find((action:any) => action.ActionName === "AddDataActivity");
      
      if (addAction) {
        addAction.Fields.forEach((field: PanelFieldData) => {
          if (field.FieldName === "BackgroundColor") {
            initialData.BackgroundColor = "Red";
          } else if (field.FieldName === "StartDate" || field.FieldName === "EndDate") {
            // Ensure dates are properly formatted
            const dateValue = field.FieldName === "StartDate" ? initialData.StartDate : initialData.EndDate;
            initialData[field.FieldName] = dateValue;
          } else {
            initialData[field.FieldName] = field.DefaultValue?.FieldValue || null;
          }
        });
  
      }
    
      // Update form state
      setFormData(initialData);
      reset(initialData); // Reset form dengan initial values
      setShowAddDialog(true);
    };

    const handleCloseAddDialog = () => {
      setShowAddDialog(false);
      if (calendar) {
        calendar.clearGridSelections();
      }
      reset({});
    };

    const handleSaveEvent = async (data: any) => {
      if (!calendar) return;

      const addAction = panelData.Actions.find((action: any) => action.ActionName === "AddDataActivity");
      
      if (addAction) {
        const fieldValues: IFieldValue[] = addAction.Fields.map((field: PanelFieldData) => {
          // if (field.InputType === "Datetime") {
          //   const date = new Date(data[field.FieldName]);
            
          //     const year = date.getFullYear();
          //     const month = String(date.getMonth() + 1).padStart(2, '0');
          //     const day = String(date.getDate()).padStart(2, '0');
          //     const hours = String(date.getHours()).padStart(2, '0');
          //     const minutes = String(date.getMinutes()).padStart(2, '0');
          //     const seconds = String(date.getSeconds()).padStart(2, '0');
          //     // console.log(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`, date, 'putra')
          //     // Format: YYYY-MM-DDTHH:mm:ss.000Z
          //     // hh24:mi:ss
          //     // const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
          //     const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            
          //   return {
          //     fieldName: field.FieldName,
          //     dataType: field.DataType,
          //     value: formattedDate
          //   };
          // }
          return {
            fieldName: field.FieldName,
            dataType: field.DataType,
            value: data[field.FieldName]
          };
        });
    
        const options: IConsumeAPI = {
          targetPanel: panelName,
          APIName: addAction.APIName,
          inputParameterValues: fieldValues,
          UseQueue: false,
          isAPIForDataTable: false,
          postActions: addAction.PostActions
        };
    
        try {
          await consumeAPI(dispatch, options);
          handleCloseAddDialog();
        } catch (error) {
          console.error('Error saving event:', error);
        } finally {
          await loadCalendarData();
        }
      }
    };

    const handleEditEvent = () => {
      if (!selectedEvent) return;

      const editData = {
        ...selectedEvent,
        StartDate: selectedEvent.start,
        EndDate: selectedEvent.end
      };

      setFormData(editData);
      reset(editData);
      setShowViewDialog(false);
      setShowEditDialog(true);
    };

    const handleUpdateEvent = async (data: any) => {
      if (!calendar || !selectedEvent) return;

      const editAction = panelData.Actions.find((action: any) => action.ActionName === "EditDataActivity");
      
      if (editAction) {
        const fieldValues: IFieldValue[] = editAction.Fields.map((field: PanelFieldData) => ({
          fieldName: field.FieldName,
          dataType: field.DataType,
          value: data[field.FieldName]
        }));

        const options: IConsumeAPI = {
          targetPanel: panelName,
          APIName: editAction.APIName,
          inputParameterValues: fieldValues,
          UseQueue: false,
          isAPIForDataTable: false,
          postActions: editAction.PostActions
        };

        try {
          await consumeAPI(dispatch, options);
          setShowEditDialog(false);
          await loadCalendarData();
        } catch (error) {
          console.error('Error updating event:', error);
        }
      }
    };

    const handleDeleteEvent = () => {
      confirmDialog({
        message: 'Are you sure you want to delete this event?',
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptClassName: 'p-button-danger',
        accept: async () => {
          if (!calendar || !selectedEvent) return;
    
          const deleteAction = panelData.Actions.find((action: any) => action.ActionName === "DeleteDataActivity");
          
          if (deleteAction) {
            const fieldValues: IFieldValue[] = [{
              fieldName: 'EventName',
              dataType: 'Text',
              value: selectedEvent.EventName
            }];
    
            const options: IConsumeAPI = {
              targetPanel: panelName,
              APIName: deleteAction.APIName,
              inputParameterValues: fieldValues,
              UseQueue: false,
              isAPIForDataTable: false,
              postActions: deleteAction.PostActions
            };
    
            try {
              setShowViewDialog(false); // Pindahkan ke sini, sebelum proses delete
              await consumeAPI(dispatch, options);
              await loadCalendarData();
            } catch (error) {
              console.error('Error deleting event:', error);
            }
          }
        }
      });
    };

    const renderDialogFields = (fields: PanelFieldData[]) => {
      return fields.map((field: PanelFieldData) => {
        const dialogField = createDialogField(field, formData[field.FieldName]);

        return (
          <div key={field.FieldName} className="flex flex-column gap-2" style={{margin:"5px"}}>
            <label htmlFor={field.FieldName}>{field.FieldLabel}</label>
            <Controller
              name={field.FieldName}
              control={control}
              defaultValue={dialogField.value}
              rules={dialogField.Mandatory ? { required: `${field.FieldLabel} is required.` } : undefined}
              render={({ field: formField, fieldState }) => (
                <FieldGenerator
                  reference={formField}
                  field={dialogField}
                  ref={formRef}
                  customProps={{
                    chartName: panelName,
                    container: {
                      widgetName,
                      panelName,
                      formRef,
                      form: {
                        setValue: (name: string, value: any) => {
                          formField.onChange(value);
                          setFormData((prev:any) => ({
                            ...prev,
                            [name]: value
                          }));
                        }
                      }
                    },
                    style: { width: '100%' }
                  }}
                  fieldState={fieldState}
                  onChange={(value:any) => {
                    formField.onChange(value);
                    setFormData((prev:any) => ({
                      ...prev,
                      [field.FieldName]: value
                    }));
                  }}
                />
              )}
            />
            {errors[field.FieldName] && (
              <small className="p-error">{errors[field?.FieldName]?.message}</small>
            )}
          </div>
        );
      });
    };

    const renderAddDialog = () => (
      <Dialog 
        header="Add New Event" 
        visible={showAddDialog} 
        style={{ width: '450px' }} 
        modal
        onHide={handleCloseAddDialog}
        footer={(
          <div>
            <Button label="Cancel" icon="pi pi-times" onClick={handleCloseAddDialog} className="p-button-text" />
            <Button label="Save" icon="pi pi-check" onClick={handleSubmit(handleSaveEvent)} />
          </div>
        )}
      >
        <div className="flex flex-column gap-4">
          {renderDialogFields(
            panelData.Actions
              .find((action: any) => action.ActionName === "AddDataActivity")
              ?.Fields || []
          )}
        </div>
      </Dialog>
    );

    const renderViewDialog = () => (
      <Dialog
        header="Event Details"
        visible={showViewDialog}
        style={{ width: '450px' }}
        modal
        onHide={() => setShowViewDialog(false)}
        footer={(
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
            {canDeleteData && (
            <Button 
              label="Delete" 
              icon="pi pi-trash" 
              className="p-button-danger" 
              onClick={handleDeleteEvent}
            />
          )}
          {canEditData && (
            <Button 
              label="Edit" 
              icon="pi pi-pencil" 
              onClick={handleEditEvent}
            />
          )}
            <Button 
              label="Close" 
              icon="pi pi-times" 
              onClick={() => setShowViewDialog(false)} 
              className="p-button-text"
            />
          </div>
        )}
      >
        {selectedEvent && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {panelData.Actions
              .find((action: any) => action?.PostActions[0]?.AddRowData === true)
              ?.Fields.map((field: PanelFieldData) => (
                <div 
                  key={field.FieldName} 
                  style={{
                    display: 'flex',
                    padding: '8px 0',
                    borderBottom: '1px solid #dee2e6',
                    minHeight: '40px',
                    alignItems: 'center'
                  }}
                >
                  <label 
                    style={{
                      fontWeight: 600,
                      color: '#4B5563',
                      minWidth: '140px'
                    }}
                  >
                    {field.FieldLabel}:
                  </label>
                  <div style={{ flex: 1, color: '#111827' }}>
                    {selectedEvent[field.FieldName] || '-'}
                  </div>
                </div>
              ))}
          </div>
        )}
      </Dialog>
    );

    const renderEditDialog = () => (
      <Dialog
        header="Edit Event"
        visible={showEditDialog}
        style={{ width: '450px' }}
        modal
        onHide={() => setShowEditDialog(false)}
        footer={(
          <div>
            <Button label="Cancel" icon="pi pi-times" onClick={() => setShowEditDialog(false)} className="p-button-text" />
            <Button label="Update" icon="pi pi-check" onClick={handleSubmit(handleUpdateEvent)} />
          </div>
        )}
      >
        <div className="flex flex-column gap-4">
          {renderDialogFields(
            panelData.Actions
              .find((action: any) => action.ActionName === "AddDataActivity")
              ?.Fields || []
          )}
        </div>
      </Dialog>
    );

    return (
      <div className="h-full">
        <div className="flex justify-between items-center mb-4 gap-2" style={{marginTop:"10px", marginBottom: "10px"}}>
          <div style={{display:"flex", gap: "5px"}}>
            <Button label="Today" onClick={() => calendar?.today()} />
            <Button icon="pi pi-chevron-left" onClick={() => calendar?.prev()} />
            <Button icon="pi pi-chevron-right" onClick={() => calendar?.next()} />
          </div>
        </div>

        <div 
          ref={containerRef} 
          style={{ height: panelData.PanelHeight || '600px' }}
          className="calendar-container"
        />

        {canAddData && renderAddDialog()}
        {renderViewDialog()}
        {canEditData && renderEditDialog()}
      </div>
    );
  };

  export default PanelCalendarActivity;