/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useRef, useEffect, useState } from 'react';

import { Button } from 'primereact/button';

import { PanelButtonData } from '../../../../../models/classes/PanelButtonData';
import { checkPermission } from '../../../../../utils/permission';
import { isEmpty } from '../../../../../utils/validation';
import __t from '../../../../../utils/translation';
import EventEmitter from '../../../../../common/EventEmitter';
import { ProgressBar } from "primereact/progressbar";

interface Props {
    panelButtons: PanelButtonData[] | null;
    onButtonClicked: Function;
    setButtonRef?: any;
    conditionalGroupAction?: Function;
    isLoading: any;
    setIsLoading: any;
    nameButton: any;
    setNameButton: any;
}


const PanelSearchButtonComponent: React.FC<Props> = (props) => {
    const { panelButtons, onButtonClicked, setButtonRef, conditionalGroupAction,} = props;

    const clickOnInitButtonRef: any = useRef(null);
    const [ClickOnInit, setClickOnInit] = useState(false)

    useEffect(() => {
        if (ClickOnInit) {
            setTimeout(() => {
                clickOnInitButtonRef?.current?.click();
            }, 50);       
        }
    }, [clickOnInitButtonRef, ClickOnInit]);

    const renderButton = (key: number, buttonData?: PanelButtonData, selfAlignment?: string): JSX.Element => {
        const onButtonClick = (e: any) => {
            // e?.preventDefault();
            if (buttonData) {
                if (!isEmpty(buttonData.PreActions)) {
                    for (let index = 0; index < buttonData.PreActions.length; index++) {
                        const preAction = buttonData.PreActions[index];
                        if (preAction.Type === 'ConditionalGroup' && !isEmpty(preAction.APIName)) {
                            if (conditionalGroupAction) {
                                props.setIsLoading(true);
                                props.setNameButton(buttonData.ButtonName)
                                conditionalGroupAction(preAction, (data: any) => {
                                    if (data) {
                                        if (Array.isArray(data)) {
                                            const dataEmits:any[] = [];
                                            data.forEach(item => {
                                                if (item.hasOwnProperty('ConditionalGroup') && !isEmpty(item.ConditionalGroup)) {
                                                    if (Array.isArray(buttonData.OnClickAction)) {
                                                        const actions = buttonData.OnClickAction.filter(a => a.ConditionalGroup === item.ConditionalGroup)
                                                        actions.map(a => {
                                                            dataEmits.push(a)
                                                            return a;
                                                        })
                                                        onButtonClicked(
                                                            buttonData.TargetPanel,
                                                            actions,
                                                            buttonData.APIName,
                                                            buttonData.ButtonName,
                                                            buttonData.IgnoreValidation,
                                                            buttonData?.TargetWidget
                                                        );
                                                    }
                                                }
                                            });
                                            EventEmitter.emit('reload_widget', dataEmits);
                                            console.log("data to emits", dataEmits);
                                        } else {
                                            onButtonClicked(
                                                buttonData.TargetPanel,
                                                buttonData.OnClickAction,
                                                buttonData.APIName,
                                                buttonData.ButtonName,
                                                buttonData.IgnoreValidation,
                                                buttonData?.TargetWidget
                                            );
                                        }
                                    }
                                })
                            }
                        }
                    }
                } else {
                    onButtonClicked(
                        buttonData.TargetPanel,
                        buttonData.OnClickAction,
                        buttonData.APIName,
                        buttonData.ButtonName,
                        buttonData.IgnoreValidation,
                        buttonData?.TargetWidget
                    );
                }
            }
        };

        buttonData!.Hidden = checkPermission(buttonData!);
        return <div
            key={key}
            className="p-col"
            style={{
                // display: "flex",
                // flexDirection: "column"
                flexGrow: 0,
                visibility: buttonData?.Hidden? "hidden": "visible"
            }}>
            {
                buttonData ?
                    <>
                        <Button
                            className="p-button-raised"
                            raised
                            icon={`pi ${buttonData.Icon}`}
                            iconPos={buttonData.IconPosition}
                            disabled={props.isLoading}
                            label={__t(buttonData, "ButtonLabel")}
                            style={{
                                width: buttonData.ButtonWidth,
                                alignSelf: selfAlignment,
                                backgroundColor: buttonData.BackgroundColor || '#2196f3',
                                color: buttonData.TextColor || '#ffffff',
                                ...(!isEmpty(buttonData?.ValueFormatStyle)) && { ...buttonData?.ValueFormatStyle }
                            }}
                            //{...(buttonData.ClickOnInit? {ref: clickOnInitButtonRef} : {})}
                            ref={(el: any) => { 
                                clickOnInitButtonRef.current = el; 
                                if (setButtonRef) {
                                    setButtonRef(clickOnInitButtonRef)
                                }
                                if (buttonData!.ClickOnInit) {
                                    setClickOnInit(buttonData!.ClickOnInit)    
                                }
                            }}
                            onClick={(e) => onButtonClick(e)}
                            tooltip={!isEmpty(buttonData.Tooltip) ? buttonData.Tooltip : undefined}
                            tooltipOptions={{
                                position: buttonData.TooltipPosition || 'right'
                            }}
                            type={buttonData.IgnoreValidation ? "button" : "submit"}
                        />
                        {props.isLoading && buttonData.ButtonName === props.nameButton && <ProgressBar mode="indeterminate" style={{ height: '2px' }}></ProgressBar>}
                    </>
                     :
                    <div/>
            }
        </div>
    };

    const renderColumns = (): (JSX.Element)[]|undefined => {
        if(panelButtons != null) {
            return panelButtons.map((buttonData: PanelButtonData, index) => {
                const selfAlignment = buttonData.ButtonAlignment === "center" ?
                    "center" :
                    buttonData.ButtonAlignment === "left" ?
                        "flex-start" :
                        "flex-end";
                return renderButton(index, buttonData, selfAlignment);
            });
        }
    };

    return (
        <div className="p-grid">
            {renderColumns()}
        </div>
    )
};

export default  PanelSearchButtonComponent;
